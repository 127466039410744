import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import Logo from '../../svg/ropeandtire'
import config from '../../../data/SiteConfig'
import './Header.css'

const Header = () => (
  <Fragment>
    <div className="header-container">
      <div className="header-containerLeft">
        <Link className="header-logo--link" to="/">
          <Logo classname="header-logo" />
        </Link>
        <div className="header-title">
          <Link to="/">
            <h2>{config.siteTitle}</h2>
            <h6>{config.siteDescription}</h6>
          </Link>
        </div>
      </div>
    </div>
  </Fragment>
)

export default Header
