import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import './Layout.css'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import config from '../../data/SiteConfig'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    return (
      <div className="app">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <script src="https://hypothes.is/embed.js" async></script>
        </Helmet>
        <Header />
        <div className="app-content">{children}</div>
        <Footer />
        {/* <footer>
          © 2020, Built with <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </div>
    )
  }
}

export default Layout
