const config = {
  siteTitle: 'Rope and Tire',
  siteTitleShort: 'Rope and Tire',
  siteTitleAlt: "Suprada's blog",
  siteLogo: '/logos/ropeandtire.png',
  siteUrl: `https://blog.suprada.com`,
  siteDescription: "Suprada's blog",
  siteRss: '/rss.xml',
  dateFromFromat: 'YYYY-MM-DD',
  userName: 'Suprada Urval',
  userEmail: 'suprada@suprada.com',
  userTwitter: '@suprada',
  copyright: 'Copyright 2020. Suprada Urval',
  themeColor: '#49618c', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
  pathPrefix: '/',
  credits: [
    {
      type: 'Logo',
      maker: 'Logomakr',
      makerUrl: 'https://logomakr.com',
    },
    {
      type: 'Theme',
      maker: 'Typography Theme - Lincoln',
      makerUrl:
        'https://github.com/KyleAMathews/typography.js/tree/master/packages/typography-theme-lincoln',
    },
    {
      type: 'Built Using ',
      maker: 'Gatsby',
      makerUrl: 'https://www.gatsbyjs.org/',
    },
    {
      type: 'Web Developer',
      maker: 'Suprada Urval',
      makerUrl: 'https://suprada.com',
    },
  ],
}

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  // console.log("PATH prefix is /");
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') {
  console.log('in here')
  config.siteUrl = config.siteUrl.slice(0, -1)
}

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/')
  config.siteRss = `/${config.siteRss}`

module.exports = config
