import React from 'react'

const Ropeandtire = props => {
  const { classname } = props
  return (
    <svg
      version="1.1"
      className={classname || ''}
      id="ropeandtire"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 400 400"
      xmlSpace="preserve"
    >
      <g
        transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
        fill="#374855"
        stroke="none"
      >
        <path
          d="M1861 3990 c-75 -10 -98 -19 -72 -28 26 -9 89 -72 99 -100 14 -36 32
-212 32 -315 0 -177 -28 -563 -51 -702 -40 -241 -153 -378 -353 -430 -74 -19
-232 -19 -321 0 -39 9 -92 20 -120 26 -112 24 -266 63 -392 100 -18 5 -48 13
-65 18 -119 33 -234 74 -314 112 -65 31 -104 81 -104 133 0 14 -3 26 -8 26
-19 0 -117 -280 -137 -393 -7 -42 -9 -42 54 0 55 37 159 29 336 -28 317 -100
482 -163 674 -257 226 -110 303 -199 351 -410 29 -128 -12 -250 -187 -552
-139 -239 -317 -493 -428 -608 -61 -62 -61 -62 -118 -62 -31 0 -57 -4 -57 -9
0 -16 211 -175 300 -227 64 -37 70 -37 58 -1 -22 65 -20 101 5 157 68 152 489
699 665 862 82 77 187 118 300 118 152 0 282 -57 392 -171 102 -107 289 -333
425 -515 36 -49 73 -98 82 -108 46 -56 112 -171 118 -204 3 -21 1 -58 -4 -82
-6 -25 -11 -48 -11 -52 -1 -25 345 229 358 263 2 5 -24 9 -58 9 -34 0 -73 6
-89 14 -88 46 -569 761 -641 953 -25 66 -27 189 -5 275 41 155 115 247 255
315 307 149 683 295 894 348 81 21 127 11 189 -37 l38 -30 -7 43 c-3 24 -9 55
-14 69 -5 14 -19 59 -31 100 -12 41 -26 79 -31 85 -4 5 -8 14 -8 21 0 19 -44
114 -52 114 -5 0 -8 -7 -8 -15 0 -23 -29 -76 -57 -107 -35 -37 -204 -103 -408
-159 -22 -6 -51 -15 -65 -19 -14 -5 -83 -23 -155 -40 -71 -17 -146 -35 -165
-40 -19 -5 -60 -14 -90 -21 -30 -6 -68 -15 -84 -20 -41 -13 -223 -11 -265 2
-174 53 -298 181 -339 349 -12 50 -25 137 -43 285 -19 147 -18 757 0 810 14
41 73 105 97 105 8 0 14 4 14 9 0 21 -264 35 -379 21z m193 -1535 c18 -17 26
-34 26 -58 0 -36 -30 -77 -56 -77 -8 0 -12 -4 -9 -10 3 -5 20 -10 36 -10 30 0
81 -21 129 -53 l25 -16 -3 40 c-5 53 26 89 77 89 48 0 81 -32 81 -80 0 -53
-35 -84 -89 -78 l-40 3 16 -25 c32 -48 53 -99 53 -130 0 -16 5 -30 10 -30 6 0
10 6 10 14 0 22 40 46 75 46 79 0 109 -98 46 -147 -35 -27 -121 -4 -121 33 0
8 -4 14 -10 14 -5 0 -10 -14 -10 -30 0 -30 -35 -116 -51 -126 -20 -12 -7 -24
28 -24 76 0 112 -82 58 -135 -52 -53 -135 -18 -135 57 0 35 -12 50 -24 29 -3
-5 -26 -18 -51 -30 -34 -16 -64 -21 -125 -21 -81 0 -116 10 -180 53 l-25 16 3
-40 c5 -53 -26 -89 -77 -89 -48 0 -81 32 -81 80 0 53 35 84 89 78 l40 -3 -16
25 c-32 48 -53 99 -53 130 0 16 -4 30 -10 30 -5 0 -10 -6 -10 -14 0 -22 -40
-46 -75 -46 -79 0 -109 98 -46 147 35 27 121 4 121 -33 0 -8 5 -14 10 -14 6 0
10 14 10 30 0 30 35 116 51 126 20 12 7 24 -28 24 -77 0 -111 80 -57 134 54
53 134 20 134 -56 0 -35 12 -50 24 -29 10 16 95 51 125 51 17 0 33 5 36 10 3
6 1 10 -6 10 -28 0 -59 43 -59 81 0 70 81 103 134 54z m3 -797 c35 -33 32 -90
-6 -117 -38 -28 -72 -26 -105 4 -33 30 -35 81 -6 113 28 30 86 30 117 0z"
        />
        <path
          d="M1978 2408 c-8 -21 2 -38 22 -38 19 0 34 25 24 41 -8 13 -41 11 -46
-3z"
        />
        <path
          d="M2260 2294 c-11 -12 -10 -18 3 -32 16 -15 18 -15 34 0 13 14 14 20 3
32 -7 9 -16 16 -20 16 -4 0 -13 -7 -20 -16z"
        />
        <path
          d="M1700 2281 c0 -24 13 -33 35 -25 23 9 14 38 -13 42 -16 3 -22 -2 -22
-17z"
        />
        <path
          d="M1925 2243 c-48 -13 -107 -61 -144 -118 -23 -35 -26 -50 -26 -125 0
-80 2 -88 33 -132 18 -26 54 -62 80 -80 44 -31 52 -33 132 -33 80 0 88 2 132
33 26 18 62 54 80 80 31 44 33 52 33 132 0 80 -2 88 -33 132 -18 26 -53 62
-79 79 -40 28 -56 33 -117 35 -39 2 -80 0 -91 -3z m165 -44 c58 -26 77 -44
106 -101 16 -31 24 -64 24 -98 0 -54 -32 -130 -67 -162 -33 -30 -107 -58 -153
-58 -49 0 -134 35 -162 66 -30 34 -58 108 -58 154 0 46 28 120 58 154 28 31
113 66 162 66 25 0 65 -10 90 -21z"
        />
        <path
          d="M1912 2140 c-48 -30 -72 -75 -72 -140 0 -99 60 -160 158 -160 63 0
102 18 136 63 22 29 26 44 26 101 0 66 -1 67 -45 111 -43 44 -46 45 -107 45
-45 0 -72 -6 -96 -20z"
        />
        <path
          d="M1593 2023 c-17 -6 -16 -40 1 -46 8 -3 22 0 32 8 15 11 16 15 5 29
-14 17 -18 18 -38 9z"
        />
        <path
          d="M2370 2014 c-11 -12 -11 -18 2 -30 8 -8 22 -12 31 -8 21 8 22 40 1
48 -21 8 -20 8 -34 -10z"
        />
        <path
          d="M1700 1734 c-11 -12 -10 -18 3 -32 16 -15 18 -15 34 0 13 14 14 20 3
32 -7 9 -16 16 -20 16 -4 0 -13 -7 -20 -16z"
        />
        <path
          d="M2261 1741 c-19 -12 -6 -41 19 -41 16 0 21 5 18 22 -3 25 -17 32 -37
19z"
        />
        <path
          d="M1976 1614 c-8 -21 1 -34 24 -34 19 0 30 17 23 38 -7 17 -40 15 -47
-4z"
        />
        <path
          d="M2300 3935 c-30 -12 -69 -35 -86 -51 -27 -26 -32 -38 -38 -101 -4
-40 -5 -77 -2 -82 3 -5 30 -12 59 -16 293 -36 573 -152 822 -340 74 -56 234
-215 302 -300 46 -58 148 -226 184 -302 14 -30 31 -53 40 -53 30 0 119 52 141
83 19 27 23 45 23 117 0 95 -4 103 -123 270 -259 364 -681 657 -1102 765 -36
9 -81 20 -101 26 -48 12 -55 11 -119 -16z"
        />
        <path
          d="M1545 3944 c-11 -2 -40 -10 -65 -15 -56 -14 -155 -46 -195 -64 -16
-7 -50 -21 -75 -31 -63 -24 -222 -108 -278 -145 -259 -175 -554 -484 -647
-676 -34 -70 -44 -185 -21 -234 17 -36 55 -65 116 -86 59 -21 66 -18 98 51
163 347 489 655 873 825 119 52 299 100 441 116 68 8 75 19 58 103 -20 99 -89
149 -212 157 -40 2 -82 2 -93 -1z"
        />
        <path
          d="M1780 3631 c-19 -5 -66 -14 -105 -21 -194 -36 -451 -148 -623 -272
-147 -106 -322 -285 -420 -430 -36 -53 -132 -232 -132 -247 0 -9 41 -24 140
-51 25 -7 55 -16 67 -20 25 -10 34 -2 72 70 81 156 261 357 422 472 103 74
272 157 399 196 82 26 125 36 238 58 22 4 23 6 20 127 -3 136 -2 134 -78 118z"
        />
        <path
          d="M2167 3634 c-13 -13 -8 -220 6 -232 6 -5 35 -13 62 -17 296 -40 588
-188 808 -410 88 -88 143 -159 223 -288 28 -46 57 -81 65 -80 31 3 132 34 159
48 l28 15 -25 52 c-173 361 -514 673 -886 812 -101 38 -257 80 -327 89 -25 4
-59 9 -75 12 -17 4 -33 3 -38 -1z"
        />
        <path
          d="M3704 2406 c-31 -14 -31 -14 -22 -62 16 -92 38 -289 38 -348 0 -105
-36 -359 -65 -451 -7 -22 -18 -60 -25 -85 -31 -104 -117 -279 -193 -395 -68
-102 -122 -169 -216 -268 -61 -63 -64 -68 -51 -91 7 -14 28 -40 47 -58 32 -31
38 -33 101 -31 92 2 146 27 203 94 69 81 172 226 209 294 17 33 35 65 39 70
10 14 84 187 110 255 24 65 55 175 69 245 6 28 16 79 24 115 9 44 13 146 13
310 l0 245 -38 58 c-65 101 -158 140 -243 103z"
        />
        <path
          d="M140 2392 c-19 -11 -54 -45 -76 -78 -41 -58 -42 -60 -54 -188 -27
-280 37 -646 162 -926 57 -128 153 -294 219 -380 122 -159 160 -198 212 -218
119 -46 174 -38 237 38 22 26 40 53 40 59 0 7 -31 42 -69 79 -147 143 -300
370 -375 557 -74 183 -105 314 -127 532 -12 120 -6 227 28 475 6 45 -6 53 -97
62 -53 5 -71 3 -100 -12z"
        />
        <path
          d="M3515 2345 c-72 -26 -90 -36 -88 -51 1 -11 7 -53 15 -94 21 -121 16
-394 -10 -512 -62 -283 -184 -513 -384 -723 l-38 -41 46 -67 c25 -38 51 -70
59 -73 23 -9 162 143 257 281 191 276 287 588 288 934 0 73 -5 163 -11 200
-15 97 -32 172 -38 175 -3 1 -46 -12 -96 -29z"
        />
        <path
          d="M391 2303 c-42 -215 -43 -428 0 -638 45 -223 150 -462 285 -646 53
-72 233 -259 249 -259 6 0 32 29 57 63 78 109 78 98 6 175 -35 37 -82 92 -105
122 -37 50 -123 187 -123 196 0 2 -13 30 -28 61 -32 67 -85 232 -100 313 -36
193 -40 296 -16 460 8 58 16 113 17 123 2 16 -17 25 -103 53 -58 19 -110 34
-116 34 -6 0 -16 -26 -23 -57z"
        />
        <path
          d="M1283 708 c-44 -50 -95 -129 -90 -141 7 -18 159 -92 282 -136 139
-50 391 -91 555 -91 85 1 232 17 315 35 161 35 277 76 415 148 111 58 110 56
65 121 -67 97 -62 96 -163 48 -192 -90 -241 -105 -483 -142 -82 -12 -266 -13
-322 -1 -23 5 -73 15 -112 22 -38 6 -79 15 -90 19 -11 5 -40 14 -65 21 -54 15
-111 39 -205 85 -38 19 -73 34 -76 34 -3 0 -15 -10 -26 -22z"
        />
        <path
          d="M2780 475 c-124 -63 -160 -79 -220 -98 -236 -75 -460 -101 -681 -81
-145 14 -167 17 -249 35 -138 31 -225 61 -357 125 -62 30 -116 54 -119 54 -10
0 -31 -29 -54 -72 -37 -70 -22 -139 48 -219 33 -37 57 -51 139 -83 137 -53
138 -53 228 -72 276 -58 392 -68 580 -55 182 14 309 34 435 70 238 67 356 120
396 180 39 60 57 129 42 166 -18 46 -59 105 -72 104 -6 0 -58 -25 -116 -54z"
        />
      </g>
    </svg>
  )
}

export default Ropeandtire
